<template>
  <a-calendar />
</template>

<script >

</script>

<style>
/* For demo */
.ant-carousel :deep(.slick-slide) {
	text-align: center;
	height: 160px;
	line-height: 160px;
	background: #364d79;
	overflow: hidden;
}

.ant-carousel :deep(.slick-slide h3) {
	color: #fff;
}
</style>
